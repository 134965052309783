import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import Article from './components/Article';
import CategoryView from './components/CategoryView';
import AllArticlesView from './components/AllArticlesView';
import AdminLogin from './components/admin/AdminLogin';
import AdminDashboard from './components/admin/AdminDashboard';
import SiteStatistics from './components/admin/SiteStatistics';
import ManageArticles from './components/admin/ManageArticles';
import EditArticle from './components/admin/EditArticle';
import CreateArticle from './components/admin/CreateArticle';
import ProtectedRoute from './components/ProtectedRoute'; // Ensure this is correctly imported
import About from './components/About';
import { getApiUrl } from './utils/config'; // Import getApiUrl

const App = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(getApiUrl('/articles')); // Use getApiUrl
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error("Could not fetch articles: ", error);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    // Matomo Tag Manager
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://prtanalytics.com/js/container_G4omzF5r.js'; s.parentNode.insertBefore(g,s);
  }, []);

  return (
    <Router>
      <div id="root">
        <Navbar />
        <main>
          <Routes>
            <Route path="/articles" element={<AllArticlesView />} />
            <Route path="/" element={<HomePage articles={articles} />} />
            <Route path="/article/:id" element={<Article />} />
            <Route path="/category/:categoryName" element={<CategoryView />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/dashboard" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />
            <Route path="/admin/statistics" element={<ProtectedRoute><SiteStatistics /></ProtectedRoute>} />
            <Route path="/admin/articles" element={<ProtectedRoute><ManageArticles /></ProtectedRoute>} />
            <Route path="/admin/articles/edit/:id" element={<ProtectedRoute><EditArticle /></ProtectedRoute>} />
            <Route path="/admin/articles/create" element={<ProtectedRoute><CreateArticle /></ProtectedRoute>} />
            <Route path="/about" element={<About />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
