import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HeroSection from './HeroSection';
import ArticleList from './ArticleList';
import { getApiUrl } from '../utils/config';

const HomePage = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(getApiUrl('/articles'));
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error("Could not fetch articles: ", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Helmet>
        <title>Personal Research Times - Curated Content and Insights</title>
        <meta name="description" content="Discover curated content full of carefully considered opinions and insights at Personal Research Times." />
      </Helmet>
      <HeroSection 
        title="Personal Research Times" 
        description="Discover curated content full of carefully considered opinions and insights."
        backgroundColor="#C2E4F6"
        textColor="#000000"
        showBackgroundAnimation={true}
      />
      <ArticleList articles={articles} limit={5} />
    </div>
  );
};

export default HomePage;
