import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArticlePreview from './ArticlePreview';
import './ArticleList.css';

const ArticleList = ({ articles, singleColumn = false, limit = null, categoryContext = false }) => {
  const navigate = useNavigate();

  const handleArticleClick = (articleId) => {
    const queryParams = categoryContext ? '?category=true' : '';
    navigate(`/article/${articleId}${queryParams}`);
  };

  if (!articles || articles.length === 0) {
    return <div>Loading...</div>;
  }

  const normalizedArticles = articles.map(article => ({
    ...article,
    imageUrl: article.photo ? article.photo.replace(/\\/g, '/') : null
  }));

  const sortedArticles = normalizedArticles.sort((a, b) => new Date(b.publishedDate) - new Date(a.publishedDate));

  const displayedArticles = limit ? sortedArticles.slice(0, limit) : sortedArticles;

  return (
    <div className={singleColumn ? "articles-list single-column" : "articles-list"}>
      {displayedArticles.map((article, index) => (
        <div key={article._id} className="articlePreviewList" onClick={() => handleArticleClick(article._id)}>
          <ArticlePreview
            _id={article._id}
            title={article.title}
            excerpt={article.excerpt}
            imageUrl={article.photo ? article.photo.replace(/\\/g, '/') : null}
            isLarge={index === 0 && !singleColumn}
            categoryView={singleColumn}
            author={article.author}
            publishedDate={article.publishedDate}
          />
        </div>
      ))}
    </div>
  );
};

export default ArticleList;
