import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenExpired, logout } from '../../utils/auth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './CreateArticle.css';
import { getApiUrl } from '../../utils/config';

const CreateArticle = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [category, setCategory] = useState('');
  const [file, setFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isTokenExpired()) {
      logout();
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(getApiUrl('/categories'));
        if (!response.ok) throw new Error('Failed to fetch categories');
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError('Failed to load categories. Please try again later.');
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('author', author);
    formData.append('category', category);
    if (file) {
      formData.append('photo', file);
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(getApiUrl('/articles'), {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData,
        credentials: 'include'
      });
      const data = await response.json();
      if (response.ok) {
        alert('Article created successfully!');
        resetForm();
      } else {
        throw new Error(data.message || 'Failed to create article');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(`Error creating article: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setContent('');
    setAuthor('');
    setCategory('');
    setFile(null);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setFile(selectedFile);
    } else {
      setError('Please select a valid image file.');
    }
  };

  return (
    <div className="full-page-container">
      <div className="create-article-container">
        <h2>Create Article</h2>
        {error && <p className="error-message">{error}</p>}
        <form className="create-article-form" onSubmit={handleSubmit}>
          <input type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder="Title" required />
          <ReactQuill value={content} onChange={setContent} placeholder="Content" />
          <input type="text" value={author} onChange={e => setAuthor(e.target.value)} placeholder="Author" required />
          <select value={category} onChange={e => setCategory(e.target.value)} required>
            <option value="">Select Category</option>
            {categories.map(cat => (
              <option key={cat._id} value={cat._id}>{cat.name}</option>
            ))}
          </select>
          <input type="file" onChange={handleFileChange} accept="image/*" />
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Article'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateArticle;
