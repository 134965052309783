import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import './Article.css';
import { getApiUrl } from '../utils/config';

const Article = () => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextId, setNextId] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [nextTitle, setNextTitle] = useState("Next Article");
  const [prevTitle, setPrevTitle] = useState("Previous Article");
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('articleDarkMode');
    if (savedMode !== null) {
      return JSON.parse(savedMode);
    }
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  const { id } = useParams();
  const location = useLocation();
  const isCategoryView = new URLSearchParams(location.search).get('category') === 'true';

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(getApiUrl(`/articles/${id}`));
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setArticle(data);
      } catch (error) {
        console.error(`Error fetching article with ID ${id}: ${error}`);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchNavigationIds = async () => {
      const baseUrl = getApiUrl(`/articles/${id}`);
      const nextUrl = isCategoryView ? `${baseUrl}/nextInCategory` : `${baseUrl}/next`;
      const prevUrl = isCategoryView ? `${baseUrl}/prevInCategory` : `${baseUrl}/prev`;

      try {
        const [nextRes, prevRes] = await Promise.all([
          fetch(nextUrl),
          fetch(prevUrl)
        ]);

        if (nextRes.ok) {
          const nextData = await nextRes.json();
          if (nextData.nextId) {
            setNextTitle(nextData.title);
            setNextId(nextData.nextId);
          } else {
            setNextTitle(null);
            setNextId(null);
          }
        } else {
          setNextTitle(null);
          setNextId(null);
        }

        if (prevRes.ok) {
          const prevData = await prevRes.json();
          if (prevData.prevId) {
            setPrevTitle(prevData.title);
            setPrevId(prevData.prevId);
          } else {
            setPrevTitle(null);
            setPrevId(null);
          }
        } else {
          setPrevTitle(null);
          setPrevId(null);
        }
      } catch (error) {
        console.error(`Error fetching navigation IDs for article with ID ${id}: ${error}`);
        setNextTitle(null);
        setNextId(null);
        setPrevTitle(null);
        setPrevId(null);
      }
    };

    fetchArticle();
    fetchNavigationIds();
  }, [id, isCategoryView]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      if (localStorage.getItem('articleDarkMode') === null) {
        setIsDarkMode(mediaQuery.matches);
      }
    };

    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
    localStorage.setItem('articleDarkMode', JSON.stringify(isDarkMode));
    console.log('Dark mode toggled:', isDarkMode);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  const calculateReadingTime = (content) => {
    const wordsPerMinute = 200;
    const wordCount = content.split(/\s+/).length;
    return Math.ceil(wordCount / wordsPerMinute);
  };

  const sanitizeAndRemoveStyles = (content) => {
    const cleanContent = DOMPurify.sanitize(content);
    const div = document.createElement('div');
    div.innerHTML = cleanContent;
    
    const removeInlineStyles = (element) => {
      element.removeAttribute('style');
      Array.from(element.children).forEach(removeInlineStyles);
    };
    
    removeInlineStyles(div);
    return div.innerHTML;
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading article: {error.message}</p>;
  if (!article) return <p>Article not found.</p>;

  const sanitizedContent = sanitizeAndRemoveStyles(article.content);

  return (
    <div id="articleContainer" className={isDarkMode ? 'dark-mode' : ''}>
      <Helmet>
        <title>{`${article.title} - Personal Research Times`}</title>
        <meta name="description" content={`Read about ${article.title} on Personal Research Times.`} />
      </Helmet>
      <div id="articleHeader">
        <h1 id="articleTitle">{article.title}</h1>
        <p id="articleAuthor">By {article.author}</p>
        <p id="articleCategory">{article.category.name}</p>
        <button onClick={toggleDarkMode} className="dark-mode-toggle">
          {isDarkMode ? '☀️ Light Mode' : '🌙 Dark Mode'}
        </button>
      </div>
      {article.photo && (
        <div id="articleImage">
          <img 
            src={article.photo} 
            alt={article.title} 
            loading="lazy"
            srcSet={`${article.photo} 1x, ${article.photoLarge || article.photo} 2x`}
          />
        </div>
      )}
      <p id="readingTime">{calculateReadingTime(article.content)} min read</p>
      <div id="articleContent" className="article-content">
        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
      </div>
      <div className="navigation-container">
        {prevId && prevTitle && (
          <Link to={`/article/${prevId}${isCategoryView ? '?category=true' : ''}`} className="navigation-link">
            <div className="navigation-content">
              <span>{prevTitle} -</span>
              <span>Previous</span>
            </div>
          </Link>
        )}
        {nextId && nextTitle && (
          <Link to={`/article/${nextId}${isCategoryView ? '?category=true' : ''}`} className="navigation-link">
            <div className="navigation-content">
              <span>{nextTitle} -</span>
              <span>Next</span>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Article;
