import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenExpired, logout } from '../../utils/auth';
import { getApiUrl } from '../../utils/config';

const SiteStatistics = () => {
  const navigate = useNavigate();
  const [statistics, setStatistics] = useState({
    totalArticles: 0,
    articlesPerCategory: [],
    mostViewedArticles: [],
    recentArticles: [],
    pageViews: 0,
    bounceRate: 0,
  });

  useEffect(() => {
    if (isTokenExpired()) {
      logout();
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const totalArticlesResponse = await fetch(getApiUrl('/statistics/total-articles'));
        const articlesPerCategoryResponse = await fetch(getApiUrl('/statistics/articles-per-category'));
        const mostViewedArticlesResponse = await fetch(getApiUrl('/statistics/most-viewed-articles'));
        const recentArticlesResponse = await fetch(getApiUrl('/statistics/recent-articles'));

        const totalArticles = await totalArticlesResponse.json();
        const articlesPerCategory = await articlesPerCategoryResponse.json();
        const mostViewedArticles = await mostViewedArticlesResponse.json();
        const recentArticles = await recentArticlesResponse.json();

        const matomoResponse = await fetch('/api/matomo');
        const matomoData = await matomoResponse.json();

        setStatistics({
          totalArticles: totalArticles.totalArticles,
          articlesPerCategory,
          mostViewedArticles,
          recentArticles,
          pageViews: matomoData.nb_visits || 0,
          bounceRate: matomoData.bounce_rate || 0,
        });
      } catch (error) {
        console.error("Could not fetch site statistics: ", error);
      }
    };

    fetchStatistics();
  }, []);

  return (
    <div>
      <h2>Site Statistics</h2>
      <div>Total Articles: {statistics.totalArticles}</div>
      <div>
        Articles Per Category:
        <ul>
          {statistics.articlesPerCategory.map((category) => (
            <li key={category.category}>{category.category}: {category.count}</li>
          ))}
        </ul>
      </div>
      <div>
        Most Viewed Articles:
        <ul>
          {statistics.mostViewedArticles.map((article) => (
            <li key={article._id}>{article.title}</li>
          ))}
        </ul>
      </div>
      <div>
        Recent Articles:
        <ul>
          {statistics.recentArticles.map((article) => (
            <li key={article._id}>{article.title}</li>
          ))}
        </ul>
      </div>
      <div>Page Views: {statistics.pageViews}</div>
      <div>Bounce Rate: {statistics.bounceRate}%</div>
    </div>
  );
};

export default SiteStatistics;
